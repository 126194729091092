export const qsologDataStore = {
    namespaced: true,
    state: {
        settings: {
            form: {
                qth_s: null,
                qthloc_s: null,
                rda_s: null,
            },
        },
        states: {
            unConfirmedCallsign: false,
        },
        pages: {
            // TODO это настройки не для logImport а для Log, переделать
            logImport: {
                tableVisibleColumns: [],
            },
        },
    },
    getters: {
        settings: ( state ) => {
            return { ...(state?.settings || {}) };
        },
        unConfirmedCallsign: ( state ) => {
            return !!state.states?.unConfirmedCallsign;
        },
        pageLogImport: ( state ) => {
            return { ...(state?.pages?.logImport || {}) };
        },
    },
    mutations: {
        setSettings( state, value ) {
            state.settings = !!value ? value : {};
        },
        setStateUnConfirmedCallsign( state, value ) {
            state.states.unConfirmedCallsign = !!value;
        },
        setPageLogImport( state, value ) {
            state.pages[ 'logImport' ] = !!value ? value : {};
        },
    },
    actions: {
        setSettings( { commit }, value ) {
            commit('setSettings', value);
        },
        setStateUnConfirmedCallsign( { commit }, value ) {
            commit('setStateUnConfirmedCallsign', value);
        },
        setPageLogImport( { commit }, value ) {
            commit('setPageLogImport', value);
        },
    },
};