const messages = {
    en: {
        'Call sign': 'Call sign',
        'My Call sign': 'My Call sign',
        'Default Call sign': 'Default Call sign',
        'Call signs': 'Call signs',
        'Settings': 'Settings',
        'Operator': 'Operator',
        'Team': 'Team',
        'Teams': 'Teams',
        'My teams': 'My teams',
        'My call signs': 'My call signs',
        'Add call sign': 'Add call sign',
        'Title': 'Title',
        'Surname': 'Surname',
        'Name': 'Name',
        'Middle': 'Middle',
        'Index': 'Index',
        'Address': 'Address',
        'City': 'City',
        'Region': 'Region',
        'Contests': 'Contests',
        'Calendar': 'Calendar',
        'Holidays': 'Holidays',
        'Radio': 'Radio',
        'Memorable': 'Memorable',
        'Events': 'Events',
        'My events': 'My events',
        'Information': 'Information',
        'Diploma': 'Diploma',
        'Diplomas': 'Diplomas',
        'Add diploma': 'Add diploma',
        'Download PDF': 'Download PDF',
        'Club': 'Club',
        'Clubs': 'Clubs',
        'My clubs': 'My clubs',
        'Add club': 'Add club',
        'Add team': 'Add team',
        'Add contest': 'Add contest',
        'Total members': 'Total members',
        'My diplomas': 'My diplomas',
        'Log': 'Log',
        'QSO': 'QSO',
        'QSOs': 'QSOs',
        'My QSO': 'My QSO',
        'First QSO': 'First QSO',
        'Last QSO': 'Last QSO',
        'Last QSOs with': 'Last QSOs with',
        'On Line': 'On Line',
        'On Air': 'On Air',
        'Only confirmed': 'Only confirmed',
        'Date': 'Date',
        'Date after': 'Date after',
        'Date before': 'Date before',
        'Valid after at': 'Valid after at',
        'Valid before at': 'Valid before at',
        'Valid from at': 'Valid from at',
        'Valid until at': 'Valid until at',
        'Time': 'Time',
        'Add': 'Add',
        'Cancel': 'Cancel',
        'Wrong value': 'Wrong value',
        'Wrong call sign': 'Wrong call sign',
        'Band': 'Band',
        'Mode': 'Mode',
        'QTH': 'QTH',
        'QTHs': 'QTHs',
        'QTHr': 'QTHr',
        'QTHLoc': 'QTHLoc',
        'RDA': 'RDA',
        'RDALoc': 'RDALoc',
        'Location': 'Location',
        'Country': 'Country',
        'State/Region': 'State/Region',
        'RSTs': 'RSTs',
        'RSTr': 'RSTr',
        'Show': 'Show',
        'Hide': 'Hide',
        'Refresh': 'Refresh',


        'Call sign S Full': 'Call sign S Full',
        'Prefix S': 'Prefix S',
        'Call sign S': 'Call sign S',
        'Additions S': 'Additions S',
        'Call sign R Full': 'Call sign R Full',
        'Prefix R': 'Prefix R',
        'Call sign R': 'Call sign R',
        'Additions R': 'Additions R',
        'Submode': 'Submode',
        'CNTY S': 'CNTY S',
        'DXCC S': 'DXCC S',
        'DXCC S Name': 'DXCC S Name',
        'QTH S': 'QTH S',
        'QTHLoc S': 'QTHLoc S',
        'Region S': 'Region S',
        'RDA S': 'RDA S',
        'CNTY R': 'CNTY R',
        'DXCC R': 'DXCC R',
        'DXCC R Name': 'DXCC R Name',
        'Name R': 'Name R',
        'QTH R': 'QTH R',
        'QTHLoc R': 'QTHLoc R',
        'Region R': 'Region R',
        'RDA R': 'RDA R',
        'SRX': 'SRX',
        'SRX String': 'SRX String',
        'STX': 'STX',
        'STX String': 'STX String',
        'Selected: num out of total': 'Selected: {selected} out of {total}',

        'Attention': 'Attention',
        'You have unconfirmed callsigns': 'You have unconfirmed callsigns',
        'Go to my callsigns': 'Go to my callsigns',
        'Without callsign confirmation you cannot confirm contacts or receive contact confirmations': 'Without callsign confirmation you cannot confirm contacts or receive contact confirmations',

        'Table settings': 'Table settings',
        'Now': 'Now',
        'Summary': 'Summary',
        'Time zone': 'Time zone',
        'TZone': 'T. zone',
        'Frequency': 'Frequency',
        'Frequency, kHz': 'Frequency, kHz',
        'Frq, kHz': 'Frq, kHz',
        'Frequency, MHz': 'Frequency, MHz',
        'Frq, MHz': 'Frq, MHz',
        'Comment': 'Comment',
        'Prefix': 'Prefix',
        'Additions': 'Additions',
        'Search': 'Search',
        'Search call sign': 'Search call sign',
        'Search call signs': 'Search call signs',
        'Date/Time': 'Date/Time',
        'Hz': 'Hz',
        'kHz': 'kHz',
        'Confirm': 'Confirm',
        'Confirmed': 'Confirmed',
        'Active': 'Active',
        'Total QSO': 'Total QSO',
        'Total grade': 'Total grade',
        'Total diplomas': 'Total diplomas',
        'Type': 'Type',
        'Main': 'Main',
        'List': 'List',
        'Geography': 'Geography',
        'Temp': 'Temp',
        'Collective': 'Collective',
        'Former': 'Former',
        'Additional': 'Additional',
        'SWL': 'SWL',
        'unknown': 'unknown',
        'Unknown': 'Unknown',
        'Admins': 'Admins',
        'Members': 'Members',
        'Templates': 'Templates',
        'Access': 'Access',
        'Administrator': 'Administrator',
        'Moderator': 'Moderator',
        'Registration': 'Registration',
        'Photo': 'Photo',
        'Auto': 'Auto',
        'Moderation': 'Moderation',
        'Contest': 'Contest',
        'Manual': 'Manual',
        'Announcement': 'Announcement',
        'Value': 'Value',
        'Status': 'Status',
        'Tax ID': 'Tax ID',
        'Contact phone': 'Contact phone',

        'Diploma template': 'Diploma template',
        'Sports discipline': 'Sports discipline',
        'Age group': 'Age group',
        'Event rank': 'Event rank',
        'Calculation of results': 'Calculation of results',
        'Start date': 'Start date',
        'Expiration date': 'Expiration date',
        'Issue date': 'Issue date',
        'Issue at': 'Issue at',
        'Serial number': 'Serial number',

        'Import from file': 'Import from file',
        'Export to ADIF': 'Export to ADIF',
        'Registration type': 'Registration type',
        'Registration start at': 'Registration start at',
        'Registration expiration at': 'Registration expiration at',
        'Event start at': 'Event start at',
        'Event expiration at': 'Event expiration at',
        'Event summing up at': 'Event summing up at',
        'Registration open': 'Registration open',
        'Calculation results': 'Calculation results',
        'Registered': 'Registered',
        'Registered participants': 'Registered participants',
        'Participants': 'Participants',
        'Number of participants': 'Number of participants',
        'Public': 'Public',
        'Conditions': 'Conditions',
        'Description': 'Description',
        'Info': 'Info',
        'Result': 'Result',
        'Results': 'Results',
        'Upload': 'Upload',
        'Add event': 'Add event',
        'Total per year QSO': 'Total per year QSO',

        'Without registering': 'Without registering',
        'Auto registration': 'Auto registration',
        'Optional registration': 'Optional registration',
        'Mandatory registration': 'Mandatory registration',

        'More': 'More',
        'Less': 'Less',
        'New': 'New',
        'Created': 'Created',
        'Accepted': 'Accepted',
        'Completed': 'Completed',
        'Summed up': 'Summed up',
        'Published': 'Published',
        'Confirmation': 'Confirmation',
        'Rejected': 'Rejected',
        'Returned': 'Returned',
        '(none)': '(none)',
        'sports_discipline_radio': 'Radio',
        'age_group_all': 'All',
        'event_rank_all': 'All',
        'calculation_results_manual': 'Manual',
        'calculation_results_auto': 'Auto',

        'Attach file': 'Attach file',
        'Default encoding for file': 'Default encoding for file',
        'The file encoding is not defined': 'The file encoding is not defined',
        'The file encoding may not be determined correctly': 'The file encoding may not be determined correctly',
        'Defined as': 'Defined as',
        'Used': 'Used',


        'Strategy for dealing with frequency errors': 'Strategy for dealing with frequency errors',
        'Automatic correction of frequency value errors and logging': 'Automatic correction of frequency value errors and logging',
        'Showing an error and rejecting a log entry': 'Showing an error and rejecting a log entry',

        'Strategy for responding to duplicates': 'Strategy for responding to duplicates',
        'Displaying a duplicate error and rejecting a log entry': 'Displaying a duplicate error and rejecting a log entry',
        'If a duplicate is detected, rewrite the log': 'If a duplicate is detected, rewrite the log',

        'Strategy for setting location value': 'Strategy for setting location value',
        'Nothing to do': 'Nothing to do',
        'Fill in if not filled in': 'Fill in if not filled in',
        'Fill in each line': 'Fill in each line',
        'Protocol for completing the diploma program': 'Protocol for completing the diploma program',

        'File size no more than': 'File size no more than',
        'Loaded': 'Loaded',
        'Total': 'Total',
        'Grade': 'Grade',
        'Save': 'Save',
        'Stop': 'Stop',
        'String': 'String',
        'Errors': 'Errors',
        'Only mistakes': 'Only mistakes',
        'Hide duplicates': 'Hide duplicates',
        'File import completed': 'File import completed',
        'Error loading string': 'Error loading string',
        'Failed to extract data from file': 'Failed to extract data from file',
        'File size is greater than': 'File size is greater than',
        'The correspondent\'s call sign is filled in incorrectly': 'The correspondent\'s call sign is filled in incorrectly',
        'The date/time is filled in incorrectly': 'The date/time is filled in incorrectly',
        'The range is filled incorrectly': 'The range is filled incorrectly',
        'Mode filled in incorrectly': 'Mode filled in incorrectly',
        'Frequency is empty, will be set by default': 'Frequency is empty, will be set by default',
        'Frequency is not within the specified range': 'Frequency is not within the specified range',
        'One or more lines do not have a call sign': 'One or more lines do not have a call sign',
        'The default callsign will be used': 'The default callsign will be used',
        'Default callsign not set': 'Default callsign not set',
        'Please do not close the window until the download is complete': 'Please do not close the window until the download is complete',
        'Confirm deletion': 'Confirm deletion',
        'Are you sure you want to delete this callsign?': 'Are you sure you want to delete this callsign?',
        'Deleting it will delete all activity with that call sign for that period': 'Deleting it will delete all activity with that call sign for that period',
        'Call sign confirmation': 'Call sign confirmation',
        'Attach a scan of the document': 'Attach a scan of the document',
    },
    ru: {
        'Call sign': 'Позывной',
        'My Call sign': 'Мой позывной',
        'Default Call sign': 'Позывной по умолчанию',
        'Call signs': 'Позывные',
        'Settings': 'Настройки',
        'Operator': 'Оператор',
        'Team': 'Команда',
        'Teams': 'Команды',
        'My teams': 'Мои команды',
        'My call signs': 'Мои позывные',
        'Add call sign': 'Добавить позывной',
        'Title': 'Название',
        'Surname': 'Фамилия',
        'Name': 'Имя',
        'Middle': 'Отчество',
        'Index': 'Индекс',
        'Address': 'Адрес',
        'City': 'Город',
        'Region': 'Регион',
        'Contests': 'Контесты',
        'Calendar': 'Календарь',
        'Holidays': 'Праздники',
        'Radio': 'Радио',
        'Memorable': 'Памятные даты',
        'Events': 'Мероприятия',
        'My events': 'Мои мероприятия',
        'Information': 'Информация',
        'Diploma': 'Диплом',
        'Diplomas': 'Дипломы',
        'Add diploma': 'Добавить диплом',
        'Download PDF': 'Скачать PDF',
        'Club': 'Клуб',
        'Clubs': 'Клубы',
        'My clubs': 'Мои клубы',
        'Add club': 'Добавить клуб',
        'Add team': 'Добавить команду',
        'Add contest': 'Добавить контест',
        'Total members': 'Всего членов',
        'My diplomas': 'Мои дипломы',
        'Log': 'Журнал',
        'QSO': 'QSO',
        'QSOs': 'QSOs',
        'My QSO': 'Мои QSO',
        'First QSO': 'Первое QSO',
        'Last QSO': 'Последнее QSO',
        'Last QSOs with': 'Последние QSOs с',
        'On Line': 'На линии',
        'On Air': 'В эфире',
        'Only confirmed': 'Только подтвержденные',
        'Date': 'Дата',
        'Date after': 'Дата после',
        'Date before': 'Дата до',
        'Valid after at': 'Действует с',
        'Valid before at': 'Действует до',
        'Valid from at': 'Действительно с',
        'Valid until at': 'Действительно до',
        'Time': 'Время',
        'Add': 'Добавить',
        'Cancel': 'Отмена',
        'Wrong value': 'Не верно',
        'Wrong call sign': 'Не верный позывной',
        'Band': 'Диапазон',
        'Mode': 'Режим',
        'QTH': 'QTH',
        'QTHs': 'QTHs',
        'QTHr': 'QTHr',
        'QTHLoc': 'QTHLoc',
        'RDA': 'RDA',
        'RDALoc': 'RDALoc',
        'Country': 'Страна',
        'Location': 'Местоположение',
        'State/Region': 'Область/Регион',
        'RSTs': 'RSTs',
        'RSTr': 'RSTr',
        'Show': 'Показать',
        'Hide': 'Спрятать',
        'Refresh': 'Обновить',

        'Call sign S Full': 'Позывной S',
        'Prefix S': 'ПрефS',
        'Call sign S': 'ПозвнS',
        'Additions S': 'ДопS',
        'Call sign R Full': 'Позывной R',
        'Prefix R': 'ПрефR',
        'Call sign R': 'ПозвнR',
        'Additions R': 'ДопR',
        'Submode': 'Подрежим',
        'CNTY S': 'CNTY S',
        'DXCC S': 'DXCC S',
        'DXCC S Name': 'DXCC S Name',
        'QTH S': 'QTH S',
        'QTHLoc S': 'QTHLoc S',
        'Region S': 'Region S',
        'RDA S': 'RDA S',
        'CNTY R': 'CNTY R',
        'DXCC R': 'DXCC R',
        'DXCC R Name': 'DXCC R Name',
        'Name R': 'Name R',
        'QTH R': 'QTH R',
        'QTHLoc R': 'QTHLoc R',
        'Region R': 'Region R',
        'RDA R': 'RDA R',
        'SRX': 'SRX',
        'SRX String': 'SRX String',
        'STX': 'STX',
        'STX String': 'STX String',
        'Selected: num out of total': 'Выбрано: {selected} из {total}',

        'Attention': 'Внимание',
        'You have unconfirmed callsigns': 'У вас имеются неподтвержденные позывные',
        'Go to my callsigns': 'Перейти в Мои позывные',
        'Without callsign confirmation you cannot confirm contacts or receive contact confirmations': 'Без подтверждения позывного Вы не можете подтверждать связи и получать подтверждения связей',

        'Table settings': 'Настройки таблицы',
        'Now': 'Сейчас',
        'Summary': 'Информация',
        'Time zone': 'Временная зона',
        'TZone': 'Вр. зона',
        'Frequency': 'Частота',
        'Frequency, kHz': 'Частота, kHz',
        'Frq, kHz': 'Чст, кГц',
        'Frequency, MHz': 'Частота, MHz',
        'Frq, MHz': 'Чст, МГц',
        'Comment': 'Комментарий',
        'Prefix': 'Префикс',
        'Additions': 'Дополнения',
        'Search': 'Поиск',
        'Search call sign': 'Поиск позывного',
        'Search call signs': 'Поиск позывных',
        'Date/Time': 'Дата/Время',
        'Hz': 'Гц',
        'kHz': 'кГц',
        'Confirm': 'Подтвердить',
        'Confirmed': 'Подтвержден',
        'Active': 'Активный',
        'Total QSO': 'Всего QSO',
        'Total grade': 'Оценка',
        'Total diplomas': 'Всего дипломов',
        'Type': 'Тип',
        'Main': 'Основной',
        'List': 'Список',
        'Geography': 'География',
        'Temp': 'Временный',
        'Collective': 'Коллективный',
        'Former': 'Бывший',
        'Additional': 'Дополнительный',
        'SWL': 'SWL',
        'unknown': 'неизвестный',
        'Unknown': 'Неизвестный',
        'Admins': 'Админы',
        'Members': 'Члены',
        'Templates': 'Шаблоны',
        'Access': 'Доступ',
        'Administrator': 'Администратор',
        'Moderator': 'Модератор',
        'Registration': 'Регистрация',
        'Photo': 'Фото',
        'Auto': 'Авто',
        'Moderation': 'Модерация',
        'Contest': 'Контест',
        'Manual': 'Ручной',
        'Announcement': 'Анонс',
        'Value': 'Значение',
        'Status': 'Статус',
        'Tax ID': 'ИНН',
        'Contact phone': 'Контактный телефон',

        'Diploma template': 'Шаблон диплома',
        'Sports discipline': 'Спортивная дисциплина',
        'Age group': 'Возрастная группа',
        'Event rank': 'Ранг мероприятия',
        'Calculation of results': 'Расчет результатов',
        'Start date': 'Дата начала',
        'Expiration date': 'Дата окончания',
        'Issue date': 'Дата выдачи',
        'Issue at': 'Выдано',
        'Serial number': 'Сер. номер',

        'Import from file': 'Импортировать из файла',
        'Export to ADIF': 'Экспорт в ADIF',
        'Registration type': 'Тип регистрации',
        'Registration start at': 'Начало регистрации',
        'Registration expiration at': 'Окончание регистрации',
        'Event start at': 'Начало мероприятия',
        'Event expiration at': 'Окончание мероприятия',
        'Event summing up at': 'Подведение итогов мероприятия',
        'Registration open': 'Регистрация открыта',
        'Calculation results': 'Подсчет результатов',
        'Registered': 'Зарегистрировано',
        'Registered participants': 'Зарегистрировано участников',
        'Participants': 'Участников',
        'Number of participants': 'Количество участников',
        'Public': 'Опубликовано',
        'Conditions': 'Условия',
        'Description': 'Описание',
        'Info': 'Информация',
        'Result': 'Результат',
        'Results': 'Результаты',
        'Upload': 'Загрузить',
        'Add event': 'Добавить мероприятие',
        'Total per year QSO': 'Всего за год QSO',

        'Without registering': 'Без регистрации',
        'Auto registration': 'Автоматическая',
        'Optional registration': 'Не обязательная',
        'Mandatory registration': 'Обязательная',

        'More': 'Больше',
        'Less': 'Меньше',
        'New': 'Новое',
        'Created': 'Создано',
        'Accepted': 'Принято',
        'Completed': 'Завершено',
        'Summed up': 'Итоги подведены',
        'Published': 'Опубликовано',
        'Confirmation': 'Подтверждение',
        'Rejected': 'Отклонено',
        'Returned': 'Возвращено',
        '(none)': '(нет)',
        'sports_discipline_radio': 'Радио',
        'age_group_all': 'Все',
        'event_rank_all': 'Все',
        'calculation_results_manual': 'Ручной',
        'calculation_results_auto': 'Автоматический',

        'Attach file': 'Приложите файл',
        'Default encoding for file': 'Кодировка по умолчанию для файла',
        'The file encoding is not defined': 'Кодировка файла не определена',
        'The file encoding may not be determined correctly': 'Кодировка файла может быть определена не корректно',
        'Defined as': 'Определена как',
        'Used': 'Используется',

        'Strategy for dealing with frequency errors': 'Стратегия действий при ошибках',
        'Automatic correction of frequency value errors and logging': 'Автоисправление ошибки значения частоты и запись в лог',
        'Showing an error and rejecting a log entry': 'Показ ошибки и отклонение записи в лог',

        'Strategy for responding to duplicates': 'Стратегия реагирования на дубли',
        'Displaying a duplicate error and rejecting a log entry': 'Показ ошибки дубля и отклонение записи в лог',
        'If a duplicate is detected, rewrite the log': 'При обнаружении дубля делать перезапись в логе',

        'Strategy for setting location value': 'Стратегия установки значения локации',
        'Nothing to do': 'Ничего не делать',
        'Fill in if not filled in': 'Заполнять если не заполнена',
        'Fill in each line': 'Заполнять в каждой строчке',
        'Protocol for completing the diploma program': 'Протокол выполнения дипломной программы',

        'File size no more than': 'Размер файла не более',
        'Loaded': 'Загружено',
        'Total': 'Всего',
        'Grade': 'Оценка',
        'Save': 'Сохранить',
        'Stop': 'Остановить',
        'String': 'Строка',
        'Errors': 'Ошибки',
        'Only mistakes': 'Только с ошибками',
        'Hide duplicates': 'Скрыть дубликаты',
        'File import completed': 'Импорт файла завершен',
        'Error loading string': 'Ошибка при загрузке строки',
        'Failed to extract data from file': 'Не удалось извлечь данные из файла',
        'File size is greater than': 'Размер файла больше',
        'The correspondent\'s call sign is filled in incorrectly': 'Позывной корреспондента указан неверно',
        'The date/time is filled in incorrectly': 'Некорректно заполнена дата/время',
        'The range is filled incorrectly': 'Некорректно заполнен диапазон',
        'Mode filled in incorrectly': 'Некорректно заполнена модуляция',
        'Frequency is empty, will be set by default': 'Частота не заполнена, будет установлена по умолчанию',
        'Frequency is not within the specified range': 'Частота не входит в заданный диапазон',
        'One or more lines do not have a call sign': 'В одной или нескольких строках не задан позывной',
        'The default callsign will be used': 'Будет использован позывной по умолчанию',
        'Default callsign not set': 'Не установлен позывной по умолчанию',
        'Please do not close the window until the download is complete': 'Пожалуйста, не закрывайте окно до окончания загрузки',
        'Confirm deletion': 'Подтвердите удаление',
        'Are you sure you want to delete this callsign?': 'Вы уверены что хотите удалить этот позывной?',
        'Deleting it will delete all activity with that call sign for that period': 'Его удаление приведет к удалению всех действий с этим позывным за этот период',
        'Call sign confirmation': 'Подтверждение позывного',
        'Attach a scan of the document': 'Приложите скан документа',
    },
};

export function useMessages() {
    return messages;
}