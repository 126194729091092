const path = '/news';
const module = 'news';

const rules = [
    {
        path: path,
        name: module,
        component: () => import('modules/news/NewsList'),
        meta: { slug: 'news', layout: 'default', title: 'News page' },
    },
    {
        path: path + '/:date/:slug',
        name: module + '-view',
        component: () => import('modules/news/NewsView'),
        meta: { layout: 'default', title: 'News page' },
    },
];

export default function getRules() {
    return rules;
}
