<template>
  <SimpleWidget
    icon="radio"
    :link="{ name: 'qsolog-main' }"
    :items="items"
  />
</template>

<script setup>
    import { onMounted, ref, watch } from 'vue';
    import { useI18n }               from 'vue-i18n';
    import { useLogger }             from 'boot/logger';
    import { useStore }              from 'boot/store';
    import { useMessages }           from '../messages';
    import SimpleWidget              from 'components/widgets/simple/SimpleWidget';

    const { t } = useI18n({
        inheritLocale: true,
        globalInjection: true,
        useScope: 'local',
        messages: useMessages(),
    });

    const logger = useLogger();
    const store = useStore();
    const userauth = ref(false);
    const items = ref([]);

    const pushItems = () => {
        items.value.length = 0;
        // items.value.push({ link: { name: 'qsolog-callsigns' }, name: t('Search call signs'), visible: true });
        items.value.push({ link: { name: 'qsolog-callsigns-my' }, name: t('My call signs'), visible: userauth.value });
        items.value.push({ link: { name: 'qsolog-log' }, name: t('My QSO'), visible: userauth.value });
        items.value.push({ link: { name: 'qsolog-diplomas' }, name: t('My diplomas'), visible: userauth.value });
        // items.value.push({ link: { name: 'qsolog-teams' }, name: t('My teams'), visible: userauth.value });
        items.value.push({ link: { name: 'qsolog-clubs' }, name: t('Clubs'), visible: true });
        items.value.push({ link: { name: 'qsolog-events' }, name: t('Events'), visible: true });
        // items.value.push({ link: { name: 'qsolog-settings' }, name: t('Settings'), visible: true });
        items.value.push({ link: { name: 'skeds-section', params: { slug: 'main' } }, name: t('SKEDS'), visible: userauth.value });
    };

    watch(() => store.getters[ 'user/authorized' ], ( value ) => {
        logger.log('Qsolog ToolbarWidget watcher user/authorized');
        userauth.value = value;
        logger.log('userauth: ' + userauth.value);
        pushItems();
    });

    onMounted(() => {
        logger.log('Qsolog ToolbarWidget onMounted');
        userauth.value = store.getters[ 'user/authorized' ];
        logger.log('userauth: ' + userauth.value);
        pushItems();
    });
</script>

<style scoped>

</style>