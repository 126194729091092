<template>
  <q-drawer
    show-if-above
    bordered
    class="print-hide bg-blue-grey-1 text-dark"
    mini-to-overlay
    :mini="miniState"
    :width="300"
    :breakpoint="500"
    @mouseover="miniState = false"
    @mouseout="miniState = true"
  >
    <q-scroll-area class="fit">
      <q-list padding>
        <MenuItem
          v-for="(item, index) in items"
          :key="`item-${index}`"
          :link="item.link"
          :target="item.target"
          :icon="item.icon"
          :name="item.name"
          :caption="item.caption"
        />
      </q-list>
    </q-scroll-area>
  </q-drawer>
</template>

<script setup>
    import { ref }  from 'vue';
    import MenuItem from './MenuItem';

    const miniState = ref(true);

    defineProps({
        items: {
            type: Array,
            default: () => [],
        },
    });
</script>

<style scoped>

</style>