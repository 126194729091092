import { createApp }     from 'vue';
import App               from './App.vue';
import router            from './router';
import Quasar from 'quasar/src/vue-plugin.js';;
import quasarUserOptions from './quasar-user-options';
import axios             from 'axios';
import VueAxios          from 'vue-axios';
import i18n              from './i18n';
// import Vuex              from 'vuex';
// import store             from '../boot/store';


createApp(App)
    .use(i18n)
    .use(router)
    .use(Quasar, quasarUserOptions)
    .use(VueAxios, axios)
    // .use(Vuex)
    .mount('#app');
