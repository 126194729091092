<template>
  <SimpleWidget
    icon="account_circle"
    :items="items"
    @click="onClick"
  />
</template>

<script setup>
    import { onMounted, ref, watch } from 'vue';
    import { useI18n }               from 'vue-i18n';
    import { useLogger }             from 'boot/logger';
    import { useStore }              from 'boot/store';
    import SimpleWidget              from 'components/widgets/simple/SimpleWidget';

    import { useMessages } from '../messages';

    const { t } = useI18n({
        inheritLocale: true,
        globalInjection: true,
        useScope: 'local',
        messages: useMessages(),
    });

    const logger = useLogger();
    const store = useStore();
    const userauth = ref();
    const items = ref([]);

    const pushItems = () => {
        items.value.length = 0;
        items.value.push({ link: { name: 'userauth-login' }, name: t('Login'), visible: !userauth.value });
        items.value.push({ link: { name: 'userauth-registration' }, name: t('Registration'), visible: !userauth.value });
        items.value.push({ link: { name: 'userauth-request-restore' }, name: t('Password request'), visible: !userauth.value });
        items.value.push({ link: { name: 'userauth-change-password' }, name: t('Change password'), visible: userauth.value });
        items.value.push({ link: { name: 'userauth-tokens' }, name: t('API Tokens'), visible: userauth.value });
        // items.value.push({ link: { name: 'userauth-profile' }, name: t('Profile'), visible: userauth.value });
        items.value.push({ link: { name: 'userauth-logout' }, name: t('Logout'), visible: userauth.value });
    };

    // TODO push наверное не нужен если реакивность есть
    watch(() => store.getters[ 'user/authorized' ], ( value ) => {
        logger.log('Userauth ToolbarWidget watcher user/authorized');
        userauth.value = value;
        logger.log('userauth: ' + userauth.value);
        pushItems();
    });

    onMounted(() => {
        logger.log('Userauth ToolbarWidget onMounted');
        userauth.value = store.getters[ 'user/authorized' ];
        logger.log('userauth: ' + userauth.value);
        pushItems();
    });

    const onClick = () => {
        // let key = Math.random() * 1000;
        // store.dispatch('session/set-session-id', 'QQQ1-' + key);
        // store.dispatch('user/set-user-token', 'QQQ2-' + key);
    };
</script>

<style scoped>

</style>