const show = (process.env.VUE_APP_ENV === 'dev');
const cats = [];

export function useLogger() {
    function contains( array, elem ) {
        return array.find(( i ) => i === elem) !== -1;
    }

    const log = ( message, cat = null ) => {
        if (show && (cat === null || contains(cats, cat))) {
            console.log(message);
        }
    };
    const debug = ( message, cat = null ) => {
        if (show && (cat === null || contains(cats, cat))) {
            console.debug(message);
        }
    };
    const error = ( message, cat = null ) => {
        if (show && (cat === null || contains(cats, cat))) {
            console.error(message);
        }
    };
    const info = ( message, cat = null ) => {
        if (show && (cat === null || contains(cats, cat))) {
            console.info(message);
        }
    };
    const exception = ( message, cat = null ) => {
        if (show && (cat === null || contains(cats, cat))) {
            console.exception(message);
        }
    };

    return { log, debug, error, info, exception };
}