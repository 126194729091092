import { createWebHistory, createRouter } from 'vue-router';
import { getModuleRoutes }                from 'boot/routine';

import { useSettings } from 'boot/settings';

const settings = useSettings();

import { useStore } from 'boot/store';

const store = useStore();

import { useLogger } from 'boot/logger';

const logger = useLogger();

import getUserauthRoutes from 'modules/userauth/rules';
import getNewsRoutes     from 'modules/news/rules';
import getTalksRoutes    from 'external/talks/rules';
import getQsologRoutes   from 'external/qsolog/rules';

const routesUserauth = getModuleRoutes(getUserauthRoutes());
const routesNews = getModuleRoutes(getNewsRoutes());
const routesTalks = getModuleRoutes(getTalksRoutes());
const routesQsolog = getModuleRoutes(getQsologRoutes());

const routesHome = [
    {
        path: '/',
        name: 'home',
        component: () => import('components/pages/LandingPage'),
        meta: { slug: 'home', layout: 'default' },
    },
    {
        path: '/home',
        redirect: { name: 'home' },
    },
];

const routesTail = [
    {
        path: '/not-found',
        name: 'error-not-found',
        component: () => import('pages/Error404'),
        meta: { layout: 'default', title: 'Error 404 page' },
    },
    {
        path: '/:pathMatch(.*)*',
        // redirect: { name: 'error-not-found' }
        component: () => import('components/pages/SimplePage'),
        meta: { layout: 'default' },
    },
];

const routes = [
    ...routesHome,
    ...routesUserauth,
    ...routesNews,
    ...routesTalks,
    ...routesQsolog,
    ...routesTail,
];


const router = createRouter({
    history: createWebHistory(),
    routes,
});

// TODO вынести потом куда то, более н зависимо может быть
router.beforeEach(( to, from, next ) => {
    logger.log(to);
    logger.log(from);

    const title = !!(to.meta?.title) ? (' | ' + to.meta.title) : '';

    document.title = settings.global.title + title;
    logger.log('Title:');
    logger.log(document.title);

    const auth = store.getters[ 'user/authorized' ];
    let need = to.meta?.auth || 'U';
    need = need.toUpperCase();

    logger.log('Auth: ' + (!!auth ? 'Y' : 'N'));
    logger.log('Need: ' + need);

    if (need === 'U') {
        logger.log('N=U, next()');
        next();
    }

    if (need === 'N') {
        if (!auth) {
            logger.log('N=N, A=F, next()');
            next();
        } else {
            logger.log('N=N, A=T, next(from)');
            next(from.path);
        }
    }

    if (need === 'Y') {
        if (!!auth) {
            logger.log('N=Y, A=T, next()');
            next();
        } else {
            logger.log('N=Y, A=F, next(login)');
            next({ name: 'userauth-login' });
        }
    }
});

export default router;
