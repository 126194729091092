<template>
  <q-toolbar
    class="print-hide bg-blue-grey-2 text-dark"
  >
    <q-btn
      v-if="showMenuBtn"
      flat
      dense
      round
      aria-label="Menu"
      icon="menu"
      class="q-mr-sm"
      @click="onClickMenuBtn"
    />

    <q-separator
      v-if="showMenuBtn"
      vertical
      inset
    />

    <q-toolbar-title>
      <q-avatar
        v-if="!!logo"
        size="md"
      >
        <img :src="logo">
      </q-avatar>
      {{ title }}
    </q-toolbar-title>

    <QsologToolBarWidget />
    <UserToolBarWidget />
  </q-toolbar>
</template>

<script setup>
    import QsologToolBarWidget from 'external/qsolog/widgets/ToolBarWidget';
    import UserToolBarWidget   from 'modules/userauth/widgets/ToolBarWidget';

    const emit = defineEmits([ 'click-menu-btn' ]);

    defineProps({
        logo: {
            type: String,
            default: '',
        },
        title: {
            type: String,
            default: 'toolbar-title-text',
        },
        showMenuBtn: {
            type: Boolean,
            default: false,
        },
    });

    const onClickMenuBtn = () => {
        emit('click-menu-btn');
    };
</script>

<style scoped>

</style>