<template>
  <q-layout view="hHh LpR lff">
    <q-header>
      <SimpleToolBar
        :logo="logo"
        :title="title"
        :show-menu-btn="showMenuBtn"
        @click-menu-btn="simpleMenuOpen = !simpleMenuOpen"
      />
    </q-header>

    <SimpleMenu
      v-if="showMenuBtn"
      v-model="simpleMenuOpen"
      :items="menuItems"
    />

    <q-page-container>
      <q-page>
        <slot />
      </q-page>
    </q-page-container>
  </q-layout>
</template>

<script setup>
    import { ref, computed } from 'vue';
    import SimpleToolBar     from 'components/toolbars/SimpleToolBar';
    import SimpleMenu        from 'components/menus/simple/Menu';

    const simpleMenuOpen = ref(false);

    const props = defineProps({
        logo: {
            type: String,
            default: '',
        },
        title: {
            type: String,
            default: 'layout-title-text',
        },
        menuItems: {
            type: Array,
            default: () => {
                return [];
            },
        },
    });

    const showMenuBtn = computed(() => {
        return (props.menuItems.length > 0);
    });
</script>

<style scoped>

</style>