<template>
  <AppLayout />
</template>

<script>
    import { defineComponent } from 'vue';
    import { useStore }        from 'boot/store';
    // import { useApi }          from 'boot/api';
    // import { useSettings }     from 'boot/settings';
    import AppLayout           from 'components/AppLayout';

    const store = useStore();
    // const { getSession, getSetting } = useApi();
    // const settings = useSettings();

    export default defineComponent({
        name: 'App',
        components: {
            AppLayout,
        },
        mounted() {
            store.dispatch('errors/clearErrors');
            // getSession()
            //     .then(() => {
            //     })
            //     .catch(() => {
            //     });
            // getSetting('global-site')
            //     .then(response => {
            //         settings.value = response.data;
            //     })
            //     .catch(() => {
            //     });
            // const { getValue } = storage();
        },
    });
</script>
