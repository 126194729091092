export const sessionDataStore = {
    namespaced: true,
    state: {
        id: '',
    },
    mutations: {
        setSessionId( state, value ) {
            state.id = value;
        },
    },
    actions: {
        setSessionId( { commit }, value ) {
            commit('setSessionId', value);
        },
    },
};