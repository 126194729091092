const path = '/talk';
const module = 'talk';
const talk = 'talk';

const skeds = 'skeds';

const rules = [
    // talk
    {
        path: path,
        name: module,
        component: () => import('external/talks/pages/MainPage'),
        meta: { layout: 'default', talk },
    },
    {
        path: path + '/:slug',
        name: module + '-section',
        component: () => import('external/talks/pages/SectionPage'),
        meta: { layout: 'default', talk, route: { 'topic': module + '-topic' } },
    },
    {
        path: path + '/:section/:slug',
        name: module + '-topic',
        component: () => import('external/talks/pages/TopicPage'),
        meta: { layout: 'default', talk },
    },

    // skeds
    // {
    //     path: '/ham/' + skeds,
    //     name: skeds,
    //     component: () => import('external/talks/pages/MainPage'),
    //     meta: { layout: 'default', talk: skeds },
    // },
    {
        path: '/ham/' + skeds + '/:slug',
        name: skeds + '-section',
        component: () => import('external/talks/pages/SectionPage'),
        meta: { layout: 'default', talk: skeds, route: { 'topic': skeds + '-topic' } },
    },
    {
        path: '/ham/' + skeds + '/:section/:slug',
        name: skeds + '-topic',
        component: () => import('external/talks/pages/TopicPage'),
        meta: { layout: 'default', talk: skeds },
    },
];

export default function getRules() {
    return rules;
}
