import { qsologDataStore } from 'external/qsolog/store';

export const externalDataStore = {
    namespaced: true,
    state: {
        token: 'test data',
    },
    getters: {
        // authorized: ( state ) => {
        //     return !!state?.token;
        // },
    },
    mutations: {
        // setUserToken( state, value ) {
        //     state.token = !!value ? value : '';
        // },
    },
    actions: {
        // setUserToken( { commit }, value ) {
        //     commit('setUserToken', value);
        // },
    },
    modules: {
        qsolog: qsologDataStore,
    },
};