<template>
  <q-item
    v-close-popup
    clickable
    @click="onClick"
  >
    <q-item-section
      v-if="icon"
      avatar
    >
      <q-icon :name="icon" />
    </q-item-section>
    <q-item-section>
      <q-item-label>{{ name }}</q-item-label>
    </q-item-section>
  </q-item>
</template>

<script setup>
    import { useRouter } from 'vue-router';

    const router = useRouter();

    const props = defineProps({
        link: {
            type: [ String, Object ],
            default: '',
        },
        name: {
            type: String,
            default: '',
        },
        icon: {
            type: String,
            default: '',
        },
    });

    const onClick = () => {
        router.push(props.link);
    };
</script>

<style scoped>

</style>
