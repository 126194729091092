export const userAuthDataStore = {
    namespaced: true,
    state: {
        access_token: null,
        valid_after: null,
        valid_before: null,
        authentication_required: false,
    },
    getters: {
        authorized: ( state ) => {
            return !!state.access_token;
        },
        authenticationRequired: ( state ) => {
            return !!state.authentication_required;
        },
    },
    mutations: {
        setUserToken( state, value ) {
            state.authentication_required = false;
            state.access_token = !!value?.access_token ? value.access_token : null;
            state.valid_after = !!value?.valid_after ? value.valid_after : null;
            state.valid_before = !!value?.valid_before ? value.valid_before : null;
        },
        clearUserToken( state ) {
            state.authentication_required = true;
            state.access_token = null;
            state.valid_after = null;
            state.valid_before = null;
        },
        setAuthenticationRequired( state, value ) {
            state.authentication_required = !!value;
        },
    },
    actions: {
        setUserToken( { commit }, value ) {
            commit('setUserToken', value);
        },
        clearUserToken( { commit } ) {
            commit('clearUserToken');
        },
        setAuthenticationRequired( { commit }, value ) {
            commit('setAuthenticationRequired', value);
        },
    },
};