export const errorsDataStore = {
    namespaced: true,
    state: {
        data: [],
    },
    getters: {
        countErrors( state ) {
            return state.data?.length || 0;
        },
        getFirstError( state ) {
            return state?.data[ 0 ] || null;
        },
    },
    mutations: {
        addError( state, value ) {
            state.data.push(value);
        },
        delFirstError( state ) {
            state.data.shift();
        },
        clearErrors( state ) {
            state.data = [];
        },
    },
    actions: {
        addError( { commit }, value ) {
            commit('addError', value);
        },
        delFirstError( { commit } ) {
            commit('delFirstError');
        },
        clearErrors( { commit } ) {
            commit('clearErrors');
        },
    },
};