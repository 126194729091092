const messages = {
    en: {
        'Cancel': 'Cancel',
        'Change password': 'Change password',
        'Confirm': 'Confirm',
        'Confirm password': 'Confirm password',
        'Current password': 'Current password',
        'Login': 'Login',
        'Logout': 'Logout',
        'New Password': 'New Password',
        'Password': 'Password',
        'Profile': 'Profile',
        'Registration': 'Registration',
        'User': 'User',
        'Username': 'Username',
        'Nickname': 'Nickname',
        'Phone': 'Phone',
        'Email': 'Email',
        'Password request': 'Password request',
        'Request': 'Request',
        'Vendor': 'Vendor',
        'Application': 'Application',
        'Version': 'Version',
        'API Tokens': 'API Tokens',
        'Add a new token': 'Add a new token',
        'Enter confirmation key': 'Enter confirmation key',
        'Current password and new password are the same': 'Current password and new password are the same',
        'Password and confirmation are not the same': 'Password and confirmation are not the same',
    },
    ru: {
        'Cancel': 'Отмена',
        'Change password': 'Смена пароля',
        'Confirm': 'Подтверждение',
        'Confirm password': 'Подтверждение пароля',
        'Current password': 'Текущий пароль',
        'Login': 'Войти',
        'Logout': 'Выйти',
        'New Password': 'Новый пароль',
        'Password': 'Пароль',
        'Profile': 'Профиль',
        'Registration': 'Регистрация',
        'User': 'Пользователь',
        'Username': 'Имя пользователя',
        'Nickname': 'Логин пользователя',
        'Phone': 'Телефон',
        'Email': 'Е-почта',
        'Password request': 'Запрос пароля',
        'Request': 'Запрос',
        'Vendor': 'Производитель',
        'Application': 'Приложение',
        'Version': 'Версия',
        'API Tokens': 'Токены АПИ',
        'Add a new token': 'Добавить новый токен',
        'Enter confirmation key': 'Введите ключ подтверждения',
        'Current password and new password are the same': 'Текущий пароль и новый пароль одинаковые',
        'Password and confirmation are not the same': 'Пароль и подтверждение не одинаковые',
    },
};

export function useMessages() {
    return messages;
}