import { reactive } from 'vue';

const items = [
    { link: { name: 'home' }, icon: 'home', name: 'Главная', caption: 'Home page' },
    { link: { name: 'news' }, icon: 'newspaper', name: 'Новости', caption: 'News in company' },
    // { link: '/files', icon: 'folder', name: 'Files', caption: 'Our Documents' },
    // { link: '/gallery', icon: 'perm_media', name: 'Gallery', caption: 'Our Documents' },
    // { link: '/documents', icon: 'description', name: 'Documents', caption: 'Our Documents' },
    // { link: '/contacts', icon: 'contact_page', name: 'Contacts', caption: 'Our contacts' },
    { link: { name: 'talk' }, icon: 'forum', name: 'Форум', caption: 'Smart talk' },
    { link: '/about', icon: 'people_outline', name: 'О нас', caption: 'About our company' },
    { link: '/faq', icon: 'question_mark', name: 'ЧаВо', caption: 'You asked' },
    { link: '/terms-of-use', icon: 'gavel', name: 'Условия использования', caption: 'Law in our company' },
    { link: '/privacy-policy', icon: 'security', name: 'Политика конфиденциальности', caption: 'Privacy in our company' },
];

// TODO сделать по нормальному
const settings = reactive({
    global: {
        // domain: 'localhost:8080',
        scheme: 'https',
        logo: '/assets/logo-default-small.png',
        // title: 'settings-title-text', // TODO
        title: 'Национальный реестр радиолюбительских связей',
    },
    template: {
        main: {
            class: {},
            colors: {},
            toolbar: {
                menu: {
                    id: 'main-menu',
                },
                widgets: {},
            },
        },
    },
    modules: [
        {
            id: 'main-menu',
            class: { name: 'main-menu' },
            options: {},
            data: { items },
        },
        {
            id: 'userauth',
            class: { name: 'userauth' },
            options: {
                registration: {
                    fields: [ 'nickname', 'email', 'password', 'confirm' ],
                    required: [ 'nickname', 'email', 'password', 'confirm' ],
                },
            },
            data: { items },
        },
        {
            id: 'qsolog',
            class: { name: 'qsolog' },
            options: {},
            data: { items },
        },
    ],
});

export function useSettings() {
    return settings;
}

export function getModuleSettings( id ) {
    let i;
    for (i = 0; i < settings.modules.length; i++) {
        if (settings.modules[ i ].id === id) {
            return settings.modules[ i ];
        }
    }
    return {};
}