const path = '/user';
const module = 'userauth';

const rules = [
    {
        path: path + '/registration',
        name: module + '-registration',
        component: () => import('modules/userauth/pages/RegistrationPage'),
        meta: { layout: 'empty', title: 'Registration page', auth: 'N' },
    },
    {
        path: path + '/login',
        name: module + '-login',
        component: () => import('modules/userauth/pages/LoginPage'),
        meta: { layout: 'empty', title: 'Login page', auth: 'N' },
    },
    {
        path: path + '/change-password',
        name: module + '-change-password',
        component: () => import('modules/userauth/pages/ChangePasswordPage'),
        meta: { layout: 'empty', title: 'Change password', auth: 'Y' },
    },
    {
        path: path + '/logout',
        name: module + '-logout',
        component: () => import('modules/userauth/pages/LogoutPage'),
        meta: { layout: 'empty', title: 'Logout page', auth: 'Y' },
    },
    {
        path: path + '/request-restore',
        name: module + '-request-restore',
        component: () => import('modules/userauth/pages/RequestRestorePage'),
        meta: { layout: 'empty', title: 'Request Restore page', auth: 'N' },
    },
    {
        path: path + '/token/:token',
        name: module + '-password-recovery',
        component: () => import('modules/userauth/pages/PasswordRecoveryPage'),
        meta: { layout: 'empty', title: 'Password Recovery page', auth: 'N' },
    },
    {
        path: path + '/api-tokens',
        name: module + '-tokens',
        component: () => import('modules/userauth/pages/ApiTokensPage'),
        meta: { layout: 'default', title: 'API Tokens page', auth: 'Y' },
    },
    // {
    //     path: path + '/profile',
    //     name: module + '-profile',
    //     component: () => import('modules/userauth/pages/ProfilePage'),
    //     meta: { layout: 'default', title: 'Profile page', auth: 'Y' },
    // },
];

export default function getRules() {
    return rules;
}
