<template>
  <component
    :is="layout"
    :logo="settings.global.logo"
    :title="settings.global.title"
    :menu-items="items"
  >
    <router-view />
  </component>
</template>

<script setup>
    import { onMounted, markRaw, ref, watch } from 'vue';
    import { useRoute }                       from 'vue-router';
    import layoutDefault                      from 'layouts/layout-default';
    import { useSettings, getModuleSettings } from 'boot/settings';

    const layout = ref();
    const route = useRoute();
    const settings = useSettings();
    const items = ref([]);

    onMounted(() => {
        let menuId = settings.template.main.toolbar.menu.id;
        const module = getModuleSettings(menuId);
        items.value = module.data.items;
    });

    watch(
        () => route.meta?.layout,
        async ( value ) => {
            try {
                const component = value && await import(`layouts/${value}.vue`);
                layout.value = markRaw(component?.default || layoutDefault);
            } catch (e) {
                layout.value = markRaw(layoutDefault);
            }
        },
        { immediate: true },
    );
</script>
