import script from "./Menu.vue?vue&type=script&setup=true&lang=js"
export * from "./Menu.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QDrawer from 'quasar/src/components/drawer/QDrawer.js';
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import QList from 'quasar/src/components/item/QList.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QDrawer,QScrollArea,QList});
